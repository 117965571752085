import { Storage } from "aws-amplify";

export const downloadFile = async (data: any, progressHandler?: any) => {
  try {
    const resp = await Storage.get(data.file, {
      download: true,
      progressCallback(progress) {
        if (progressHandler) {
          progressHandler((progress.loaded / progress.total) * 100);
        }
      },
    });
    downloadBlob(resp.Body, `${data.file}.${data.filetype}`);
  } catch (e) {
    console.error("Couldn't get download", e);
  }
};

// From amplify docs
const downloadBlob = (blob: any, filename: any) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};
