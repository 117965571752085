import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Grid, Button, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Authentication } from "../Authentication";
import CurltimeLogo from "../../Images/curltime.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { successfulAlert, errorAlert } from "../../redux/features/snackbar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { refreshState } from "../../redux/features/auth";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

export const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const refresh = useAppSelector((state) => state.auth.refresh);
  const [openAuth, setOpenAuth] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [showLoad, setShowLoad] = useState(false);

  const pages = [
    { name: "Downloads", link: "/downloads" },
    { name: "Contact", link: "/contact" },
  ];

  const closeAuth = () => {
    setOpenAuth(false);
  };

  const isAuthenticated = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuth(true);
    } catch {
      setIsAuth(false);
    }
  };

  const submitLogout = async () => {
    try {
      setShowLoad(true);
      await Auth.signOut();
      setIsAuth(false);
      dispatch(successfulAlert("Successfully Signed Out"));
    } catch {
      dispatch(errorAlert("Something went wrong signing out"));
      console.error("Something went wrong signing out");
    } finally {
      dispatch(refreshState());
      setShowLoad(false);
    }
  };
  useEffect(() => {
    isAuthenticated();
  }, [refresh]);

  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Authentication open={openAuth} handleClose={closeAuth} />
        <Toolbar>
          <Grid
            container
            alignItems={"center"}
            spacing={2}
            overflow={"auto"}
            wrap={"nowrap"}>
            <Grid item sx={{ flexGrow: 1 }}>
              <LazyLoadImage
                src={CurltimeLogo}
                style={{ maxWidth: 150, cursor: "pointer" }}
                alt={"CurlTime"}
                onClick={() => {
                  navigate("/");
                }}
              />
            </Grid>
            <>
              {pages.map((page: any) => (
                <Grid item key={page.name}>
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate(page.link);
                    }}
                    sx={{
                      my: 2,
                      color: "white",
                    }}>
                    {page.name}
                  </Button>
                </Grid>
              ))}
            </>
            {!isAuth && (
              <Grid item>
                <Button
                  variant="text"
                  onClick={(e) => {
                    setOpenAuth(true);
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}>
                  Login
                </Button>
              </Grid>
            )}
            {isAuth && (
              <Grid item>
                <LoadingButton
                  variant="outlined"
                  loading={showLoad}
                  sx={{ my: 2, color: "white", display: "block" }}
                  onClick={() => {
                    submitLogout();
                  }}>
                  Logout
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Offset />
      <Offset sx={{ display: { xs: "block", sm: "none" } }} />
    </React.Fragment>
  );
};
