/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCurltimeApplication = /* GraphQL */ `
  mutation CreateCurltimeApplication(
    $input: CreateCurltimeApplicationInput!
    $condition: ModelCurltimeApplicationConditionInput
  ) {
    createCurltimeApplication(input: $input, condition: $condition) {
      id
      name
      releases {
        items {
          id
          applicationId
          name
          description
          file
          filetype
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCurltimeApplication = /* GraphQL */ `
  mutation UpdateCurltimeApplication(
    $input: UpdateCurltimeApplicationInput!
    $condition: ModelCurltimeApplicationConditionInput
  ) {
    updateCurltimeApplication(input: $input, condition: $condition) {
      id
      name
      releases {
        items {
          id
          applicationId
          name
          description
          file
          filetype
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCurltimeApplication = /* GraphQL */ `
  mutation DeleteCurltimeApplication(
    $input: DeleteCurltimeApplicationInput!
    $condition: ModelCurltimeApplicationConditionInput
  ) {
    deleteCurltimeApplication(input: $input, condition: $condition) {
      id
      name
      releases {
        items {
          id
          applicationId
          name
          description
          file
          filetype
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCurltimeRelease = /* GraphQL */ `
  mutation CreateCurltimeRelease(
    $input: CreateCurltimeReleaseInput!
    $condition: ModelCurltimeReleaseConditionInput
  ) {
    createCurltimeRelease(input: $input, condition: $condition) {
      id
      applicationId
      name
      description
      file
      filetype
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCurltimeRelease = /* GraphQL */ `
  mutation UpdateCurltimeRelease(
    $input: UpdateCurltimeReleaseInput!
    $condition: ModelCurltimeReleaseConditionInput
  ) {
    updateCurltimeRelease(input: $input, condition: $condition) {
      id
      applicationId
      name
      description
      file
      filetype
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCurltimeRelease = /* GraphQL */ `
  mutation DeleteCurltimeRelease(
    $input: DeleteCurltimeReleaseInput!
    $condition: ModelCurltimeReleaseConditionInput
  ) {
    deleteCurltimeRelease(input: $input, condition: $condition) {
      id
      applicationId
      name
      description
      file
      filetype
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCurltimeManual = /* GraphQL */ `
  mutation CreateCurltimeManual(
    $input: CreateCurltimeManualInput!
    $condition: ModelCurltimeManualConditionInput
  ) {
    createCurltimeManual(input: $input, condition: $condition) {
      id
      name
      file
      filetype
      dummyhash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCurltimeManual = /* GraphQL */ `
  mutation UpdateCurltimeManual(
    $input: UpdateCurltimeManualInput!
    $condition: ModelCurltimeManualConditionInput
  ) {
    updateCurltimeManual(input: $input, condition: $condition) {
      id
      name
      file
      filetype
      dummyhash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCurltimeManual = /* GraphQL */ `
  mutation DeleteCurltimeManual(
    $input: DeleteCurltimeManualInput!
    $condition: ModelCurltimeManualConditionInput
  ) {
    deleteCurltimeManual(input: $input, condition: $condition) {
      id
      name
      file
      filetype
      dummyhash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDownloadForm = /* GraphQL */ `
  mutation CreateDownloadForm(
    $input: CreateDownloadFormInput!
    $condition: ModelDownloadFormConditionInput
  ) {
    createDownloadForm(input: $input, condition: $condition) {
      id
      event
      venue
      city
      country
      intendedUse
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDownloadForm = /* GraphQL */ `
  mutation UpdateDownloadForm(
    $input: UpdateDownloadFormInput!
    $condition: ModelDownloadFormConditionInput
  ) {
    updateDownloadForm(input: $input, condition: $condition) {
      id
      event
      venue
      city
      country
      intendedUse
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDownloadForm = /* GraphQL */ `
  mutation DeleteDownloadForm(
    $input: DeleteDownloadFormInput!
    $condition: ModelDownloadFormConditionInput
  ) {
    deleteDownloadForm(input: $input, condition: $condition) {
      id
      event
      venue
      city
      country
      intendedUse
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
