import { Navbar } from "./Components/Navbar";
import { Routes, Route } from "react-router-dom";
import { Home } from "./Components/Home";
import { Downloads } from "./Components/Downloads";
import { Contact } from "./Components/Contact";
import { Admin } from "./Components/Admin";
import { SnackbarAlert } from "./Components/Generics/SnackbarAlert";

const App = () => {
  return (
    <>
      <Navbar />
      <SnackbarAlert />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </>
  );
};

export default App;
