import { Snackbar, Alert } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { deactivateSnackbar } from "../../redux/features/snackbar";

export const SnackbarAlert = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector((state: RootState) => state.snackbar.active);
  const closeHandler = () => dispatch(deactivateSnackbar());
  const color = useAppSelector((state: RootState) => state.snackbar.color);
  const message = useAppSelector((state: RootState) => state.snackbar.message);

  return (
    <>
      <Snackbar
        open={open}
        onClose={() => {
          closeHandler();
        }}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            closeHandler();
          }}
          severity={color}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
