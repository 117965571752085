/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://pl48fdr0i4.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ske3danvhnfx5bzg55nam43ctq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2ekgxmxzkfa6fhzrmjaya3wjia",
    "aws_cognito_identity_pool_id": "us-east-1:c035cd4e-f847-4c86-aa70-eb7dc41ea1f8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_SYNPIRuAT",
    "aws_user_pools_web_client_id": "778i5q26m4ugdc56f4v2tnri86",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "curltimewebsitecde7f2b1454d4540b0121c585d1ab4a7133608-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
