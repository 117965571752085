import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { UserEditModal } from "./UserEditModal";
import { getGroups, User, getUsers } from "./AdminQueries";
import { Auth } from "aws-amplify";

export const UserAdminPanel = () => {
  const cols = [
    { field: "email" },
    { field: "created" },
    { field: "status" },
    { field: "edit" },
  ];
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [groups, setGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const openEditModal = (user: User) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getUsersAndGroups = async () => {
    setGroups(await getGroups());
    setUsers(await getUsers());
  };

  useEffect(() => {
    getUsers();
    getUsersAndGroups();
  }, []);

  return (
    <>
      <UserEditModal
        open={showModal}
        handleClose={handleCloseModal}
        user={selectedUser}
        allGroups={groups}
      />
      <Grid container spacing={1}>
        <Grid
          container
          item
          direction={"row"}
          alignItems={"center"}
          spacing={1}>
          {groups.map((group: any) => (
            <Grid item key={group.GroupName}>
              <Chip onClick={() => {}} label={group.GroupName} />
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {cols.map((col) => (
                    <TableCell align="left" key={col.field}>
                      {col.field}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user: User) => (
                  <TableRow key={user.id}>
                    <TableCell align="left">{user.email}</TableCell>
                    <TableCell align="left">{user.created}</TableCell>
                    <TableCell align="left">{user.status}</TableCell>
                    <TableCell align="left">
                      <IconButton
                        onClick={() => {
                          openEditModal(user);
                        }}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
