import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Grid, TextField, Typography, Link, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CodeVerification } from "./CodeVerification";
import { successfulAlert, errorAlert } from "../../redux/features/snackbar";
import { refreshState } from "../../redux/features/auth";
import { useAppDispatch } from "../../redux/hooks";

export const SignIn = (props: any) => {
  const { handleClose, handleToggleToSignUp } = props;
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [showLoad, setShowLoad] = useState(false);
  const [showCodeVerification, setShowCodeVerification] = useState(false);

  const submitLogin = async () => {
    try {
      setShowLoad(true);
      await Auth.signIn(email, password);
      handleClose();
      dispatch(successfulAlert("Successfully signed in"));
    } catch (e: any) {
      if (e.message === "User is not confirmed.") {
        await Auth.resendSignUp(email);
        setShowCodeVerification(true);
      } else {
        dispatch(errorAlert(`Something went wrong signing in: ${e}`));
      }
    } finally {
      dispatch(refreshState());
      setShowLoad(false);
    }
  };

  const submitRecoveryEmail = async () => {
    try {
      setShowLoad(true);
      await Auth.forgotPassword(recoveryEmail);
      dispatch(successfulAlert("Successfully sent recovery email"));
      setShowCodeVerification(true);
    } catch (e) {
      console.error(e);
      dispatch(errorAlert(`Something went wrong sending recovery email: ${e}`));
    } finally {
      setShowLoad(false);
    }
  };

  const postConfirmSignIn = async () => {
    try {
      await Auth.signIn(email, password);
      dispatch(successfulAlert("Successfully Signed in"));
    } catch (e) {
      console.error(e);
      dispatch(
        errorAlert(`Something went wrong signing in post-confirm: ${e}`)
      );
    } finally {
      setShowCodeVerification(false);
      dispatch(refreshState());
      handleReset();
      handleClose();
    }
  };

  const handleReset = () => {
    setEmail("");
    setPassword("");
    setRecoveryEmail("");
  };

  return (
    <>
      {showForgotPass ? (
        !showCodeVerification ? (
          <Grid
            container
            direction={"column"}
            spacing={2}
            alignItems={"center"}
            sx={{ padding: 2 }}>
            <Grid item>
              <TextField
                fullWidth
                label="Recovery Email"
                helperText="Please enter email associated with your CurlTime Account"
                onChange={(e) => setRecoveryEmail(e.target.value)}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                loading={showLoad}
                variant="contained"
                onClick={() => {
                  submitRecoveryEmail();
                }}>
                Send Recovery Email
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setShowForgotPass(false);
                }}>
                Back to Login
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CodeVerification
            email={recoveryEmail}
            postConfrimHandle={() => {
              setShowCodeVerification(false);
              setShowForgotPass(false);
            }}
            isSignUp={false}
          />
        )
      ) : !showCodeVerification ? (
        <Grid
          container
          direction={"column"}
          spacing={2}
          alignItems={"center"}
          sx={{ padding: 2 }}>
          <Grid item>
            <TextField
              fullWidth
              label="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type="password"
              label="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              loading={showLoad}
              onClick={() => {
                submitLogin();
              }}>
              Log In
            </LoadingButton>
          </Grid>
          <Grid item>
            <Typography>
              <Link
                href="#"
                onClick={() => {
                  setShowForgotPass(true);
                }}>
                Forgot Password
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              New to Curltime?{" "}
              <Link
                href="#"
                onClick={() => {
                  handleToggleToSignUp(false);
                }}>
                Sign Up!
              </Link>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <CodeVerification
          email={email}
          postConfrimHandle={() => {
            postConfirmSignIn();
          }}
          isSignUp={true}
        />
      )}
    </>
  );
};
