export const listCurltimeApplicationsWithReleasesDesc = /* GraphQL */ `
  query ListCurltimeApplications(
    $filter: ModelCurltimeApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurltimeApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        releases(sortDirection: DESC) {
          items {
            id
            applicationId
            name
            description
            file
            filetype
            createdAt
            updatedAt
          }
          nextToken
        }
        groups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getCurltimeApplicationTopLevel = /* GraphQL */ `
  query GetCurltimeApplication($id: ID!) {
    getCurltimeApplication(id: $id) {
      id
      name
      groups
      createdAt
      updatedAt
    }
  }
`;
