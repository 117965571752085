import { Auth, API } from "aws-amplify";

const API_NAME = "AdminQueries";

export type User = {
  id: string;
  email: string;
  created: string;
  status: string;
};

export type Group = {
  GroupName: string;
  Description?: string;
};

export const getGroups = async () => {
  try {
    const path = "/listGroups";
    const req = {
      body: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `${await (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(API_NAME, path, req);
    return rest.Groups;
  } catch (e) {
    console.error(e);
  }
};

export const getUsers = async () => {
  try {
    const path = "/listUsers";
    const req = {
      body: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `${await (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(API_NAME, path, req);
    return rest.Users.map((user: any) => {
      return makeUser(user);
    });
  } catch (e) {
    console.error(e);
  }
};

export const makeUser = (user: any) => {
  return {
    id: user.Username,
    email: user.Attributes.filter((item: any) => item.Name === "email")[0]
      .Value,
    created: user.UserCreateDate,
    status: user.UserStatus,
  };
};
