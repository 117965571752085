import { createSlice } from "@reduxjs/toolkit";

// Only here to refresh components which rely on admin/auth privledges for fetching

// Define a type for the slice state
interface AuthState {
  refresh: boolean;
}

// Define the initial state using that type
const initialState: AuthState = {
  refresh: false,
};

// Controls our snackbar's state
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    refreshState: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

export const { refreshState } = authSlice.actions;

export default authSlice.reducer;
