import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface SnackbarState {
  color: AlertColor;
  message: string;
  active: boolean;
}

// Define the initial state using that type
const initialState: SnackbarState = {
  color: "success",
  message: "",
  active: false,
};

// Controls our snackbar's state
export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    // Change Individual states
    changeColor: (state, action: PayloadAction<AlertColor>) => {
      state.color = action.payload;
    },
    changeMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    showSnackbar: (state) => {
      state.active = true;
    },
    deactivateSnackbar: (state) => {
      state.active = false;
    },
    // Macros to activate snackbar with different colors
    successfulAlert: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.color = "success";
      state.active = true;
    },
    errorAlert: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.color = "error";
      state.active = true;
    },
    warningAlert: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.color = "warning";
      state.active = true;
    },
    infoAlert: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.color = "info";
      state.active = true;
    },
  },
});

export const {
  changeColor,
  changeMessage,
  showSnackbar,
  deactivateSnackbar,
  successfulAlert,
  errorAlert,
  warningAlert,
  infoAlert,
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
