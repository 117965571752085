import React, { useState } from "react";
import { Tab, Box } from "@mui/material";
import { TabPanel, TabList, TabContext } from "@mui/lab";
import { UserAdminPanel } from "./UserAdminPanel";
import { CurltimeReleaseAdmin } from "./CurltimeReleaseAdmin";

const panels = [
  { name: "Users", component: <UserAdminPanel />, index: "0" },
  { name: "Releases", component: <CurltimeReleaseAdmin />, index: "1" },
];

export const AdminTabs = () => {
  const [value, setValue] = useState("0");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {panels.map((panel) => (
              <Tab
                key={`${panel.name}-${panel.index}`}
                label={panel.name}
                value={panel.index}
              />
            ))}
          </TabList>
        </Box>
        {panels.map((panel) => (
          <TabPanel key={`${panel.name}-${panel.index}`} value={panel.index}>
            {panel.component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};
