import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  Button,
  AlertColor,
  TextField,
} from "@mui/material";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { CurltimeApplication, UpdateCurltimeApplicationInput } from "../../API";
import { updateCurltimeApplication } from "../../graphql/mutations";
import { getCurltimeApplicationTopLevel } from "../../graphql-custom/customQueries";
import { Multiselect } from "../Generics/Multiselect";
import { getGroups, Group } from "./AdminQueries";
import { successfulAlert, errorAlert } from "../../redux/features/snackbar";
import { useAppDispatch } from "../../redux/hooks";

export const EditCurltimeApplicationModal = (props: any) => {
  const { open, handleClose, applicationId, handleGetApplications } = props;
  const dispatch = useAppDispatch();

  const [application, setApplication] = useState<CurltimeApplication | null>();
  const [applicationName, setApplicationName] = useState("");
  const [groups, setGroups] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [submittingChange, setSubmittingChange] = useState(false);

  const getApplication = async () => {
    try {
      setIsLoading(true);
      const resp: any = await API.graphql({
        query: getCurltimeApplicationTopLevel,
        variables: { id: applicationId },
      });
      setApplication(resp.data.getCurltimeApplication);
      setSelectedGroups(
        resp.data.getCurltimeApplication.groups?.map((group: string) => group)
      );
      setApplicationName(resp.data.getCurltimeApplication.name);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getGroupsFromCognito = async () => {
    setGroups((await getGroups()).map((group: Group) => group.GroupName));
  };

  const handleSelectedGroupChange = (newValue: string[]) => {
    setSelectedGroups(newValue);
  };

  const handleSubmitChange = async () => {
    try {
      setSubmittingChange(true);
      const input: UpdateCurltimeApplicationInput = {
        id: applicationId,
        groups: selectedGroups,
        name: applicationName,
      };
      await API.graphql({
        query: updateCurltimeApplication,
        variables: { input: input },
      });

      dispatch(successfulAlert("Successfully updated application"));
    } catch (e) {
      console.error(e);
      dispatch(errorAlert("Something went wrong updating the roles"));
    } finally {
      setSubmittingChange(false);
      handleGetApplications();
    }
  };

  useEffect(() => {
    if (open) {
      getApplication();
      getGroupsFromCognito();
    }
  }, [open]);

  return (
    <Dialog open={open}>
      {!isLoading ? (
        <>
          {application ? (
            <>
              <DialogTitle>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}>
                  <Grid item>Edit {application.name}</Grid>
                  <Grid item>
                    <IconButton onClick={handleClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Grid
                  container
                  alignItems={"center"}
                  direction="column"
                  spacing={2}
                  sx={{ padding: 2 }}>
                  <Grid item>
                    <TextField
                      label="Name"
                      value={applicationName}
                      onChange={(e) => {
                        setApplicationName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Multiselect
                      values={groups}
                      selection={selectedGroups}
                      changeHandler={handleSelectedGroupChange}
                      label="Accessable Roles"></Multiselect>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      loading={submittingChange}
                      variant="contained"
                      onClick={() => {
                        handleSubmitChange();
                      }}>
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </DialogContent>
            </>
          ) : (
            <DialogTitle>Can't find the application...</DialogTitle>
          )}
        </>
      ) : (
        <DialogTitle>
          <CircularProgress />
        </DialogTitle>
      )}
    </Dialog>
  );
};
