import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Storage, API } from "aws-amplify";
import { createCurltimeManual } from "../../graphql/mutations";
import { CreateCurltimeManualInput } from "../../API";

const GRID_PADDING = 2;

export const CreateCurltimeManualModal = (props: any) => {
  const { open, handleClose, handleRefreshManuals } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [file, setFile] = useState<File | null>(null);
  const [manualName, setManualName] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (e: any) => {
    if (!e.target.files || e.target.files.length <= 0) {
      console.error("Select a file!");
      return;
    }
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      setShowUpload(true);
      await Storage.put(manualName, file, {
        completeCallback: (event) => {
          console.log("Upload Complete");
        },
        progressCallback: (progress) => {
          // console.log((progress.loaded / progress.total) * 100);
          setUploadProgress((progress.loaded / progress.total) * 100);
        },
        errorCallback: (err) => {
          console.error(err);
        },
      });

      const manualMetadata: CreateCurltimeManualInput = {
        name: manualName,
        file: manualName,
        filetype: file?.name.split(".").pop()!,
      };
      await API.graphql({
        query: createCurltimeManual,
        variables: { input: manualMetadata },
      });
    } catch (e) {
      console.error(e);
    } finally {
      setShowUpload(false);
      setUploadProgress(1);
      handleRefreshManuals();
    }
  };

  // Edit these to make your steps
  const steps = [
    {
      name: "Select File",
      content: (
        <>
          <Grid
            container
            direction={"column"}
            spacing={2}
            sx={{ padding: GRID_PADDING }}>
            <Grid
              item
              container
              direction={"row"}
              spacing={2}
              alignItems={"center"}>
              <Grid item>
                <Button variant="contained" component="label">
                  Select File
                  <input
                    hidden
                    type="file"
                    onChange={(e: any) => handleFileChange(e)}
                  />
                </Button>
              </Grid>
              <Grid item>
                {file?.name && (
                  <Typography>Selected file: "{file.name}"</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      name: "Name Manual",
      content: (
        <>
          <Grid container sx={{ padding: GRID_PADDING }}>
            <TextField
              fullWidth
              label="Name for Manual"
              onChange={(e) => {
                setManualName(e.target.value);
              }}
            />
          </Grid>
        </>
      ),
    },
  ];

  // Have this return true if you'd like to skip a step
  const isStepOptional = (step: number) => {
    return false;
  };

  // Keeps track of skipped steps
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped((prevSkipped: Set<number>) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setFile(null);
    setManualName("");
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        Create Release
        <IconButton
          disabled={showUpload}
          onClick={() => {
            handleReset();
            handleClose();
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label.name} {...stepProps}>
                  <StepLabel {...labelProps}>{label.name}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {showUpload ? (
                  <>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress}
                    />
                  </>
                ) : (
                  <>
                    <Typography>Upload complete!</Typography>
                  </>
                )}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button disabled={showUpload} onClick={handleReset}>
                  Reset
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {steps[activeStep].content}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button
                    onClick={() => {
                      handleNext();
                      handleUpload();
                    }}>
                    Upload
                  </Button>
                ) : (
                  <Button onClick={handleNext}>Next</Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
