import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Auth, API } from "aws-amplify";
import { User, Group } from "./AdminQueries";
import { Multiselect } from "../Generics/Multiselect";
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";

const API_NAME = "AdminQueries";

export const UserEditModal = (props: any) => {
  const { user, open, handleClose, allGroups } = props;
  const [groups, setGroups] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      getGroupsForUser();
    }
  }, [user]);

  useEffect(() => {
    setSelectedGroups(groups);
  }, [groups]);

  const getGroupsForUser = async () => {
    try {
      setIsLoading(true);
      const path = "/listGroupsForUser";
      const req = {
        queryStringParameters: { username: user.id },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };
      const { NextToken, ...rest } = await API.get(API_NAME, path, req);
      setGroups(rest.Groups.map((group: Group) => group.GroupName));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const addUserToGroup = async (user: User, group: string) => {
    try {
      const path = "/addUserToGroup";
      const req = {
        body: {
          username: user.id,
          groupname: group,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };
      await API.post(API_NAME, path, req);
    } catch (e) {
      console.error(e);
    }
  };

  const removeUserFromGroup = async (user: User, group: string) => {
    try {
      const path = "/removeUserFromGroup";
      const req = {
        body: {
          username: user.id,
          groupname: group,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${await (await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };
      await API.post(API_NAME, path, req);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      // Remove user from their original groups
      await Promise.all(
        groups.map(async (group) => await removeUserFromGroup(user, group))
      );

      // Add user to all new groups (which may include old groups too)
      await Promise.all(
        selectedGroups.map(async (group) => await addUserToGroup(user, group))
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Edit {user?.email}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              disabled={isSubmitting}
              onClick={() => {
                handleClose();
              }}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction={"column"}
          sx={{ padding: 2 }}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}>
          <Grid item>
            {!isLoading ? (
              <Multiselect
                values={allGroups.map((group: any) => group.GroupName)}
                label="Groups"
                selection={selectedGroups}
                changeHandler={(newVal: any) => setSelectedGroups(newVal)}
              />
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              onClick={() => {
                handleSubmit();
              }}>
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
