import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  Box,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import { Close } from "@mui/icons-material";

export const Authentication = (props: any) => {
  const { open, handleClose } = props;
  const [isSignIn, setIsSignIn] = useState(true);

  const handleSetSignIn = (newVal: boolean) => {
    setIsSignIn(newVal);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Typography variant="h6">{isSignIn ? "Login" : "Sign Up"}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <Box sx={{ minWidth: 350 }}>
        {isSignIn ? (
          <>
            <SignIn
              handleClose={handleClose}
              handleToggleToSignUp={handleSetSignIn}
            />
          </>
        ) : (
          <>
            <SignUp
              handleClose={handleClose}
              handleToggleToSignIn={handleSetSignIn}
            />
          </>
        )}
      </Box>
    </Dialog>
  );
};
