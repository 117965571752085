import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { TextField, Grid, Typography, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CodeVerification } from "./CodeVerification";
import { successfulAlert, errorAlert } from "../../redux/features/snackbar";
import { refreshState } from "../../redux/features/auth";
import { useAppDispatch } from "../../redux/hooks";

export const SignUp = (props: any) => {
  const { handleClose, handleToggleToSignIn } = props;
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dupePass, setDupePass] = useState("");
  const [showLoad, setShowLoad] = useState(false);
  const [showCodeVerification, setShowCodeVerification] = useState(false);

  const passwordsMatch = () => {
    return password === dupePass;
  };

  const passwordsLength = () => {
    return password.length >= 8;
  };

  const submitSignUp = async () => {
    if (passwordsLength() && passwordsMatch()) {
      try {
        setShowLoad(true);
        await Auth.signUp({
          username: email,
          password,
        });
        dispatch(
          successfulAlert(
            "Successfully made your account, please check your email for a verification code."
          )
        );
        setShowCodeVerification(true);
      } catch (e) {
        console.error(e);
        dispatch(errorAlert("Something went wrong making your account..."));
      } finally {
        setShowLoad(false);
      }
    }
  };

  const postConfirmSignIn = async () => {
    try {
      await Auth.signIn(email, password);
      dispatch(successfulAlert("Successfully Signed in"));
    } catch (e) {
      console.error(e);
      dispatch(
        errorAlert(`Something went wrong signing in post-confirm: ${e}`)
      );
    } finally {
      setShowCodeVerification(false);
      dispatch(refreshState());
      handleClose();
    }
  };

  return (
    <>
      <Grid
        container
        direction={"column"}
        spacing={2}
        alignItems={"center"}
        sx={{ padding: 2 }}>
        {!showCodeVerification ? (
          <>
            <Grid item>
              <TextField
                required
                fullWidth
                type="email"
                variant="outlined"
                label="Email"
                placeholder="name@domain.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Password"
                type="password"
                error={!passwordsMatch() || !passwordsLength()}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Confirm Password"
                type="password"
                error={!passwordsMatch() || !passwordsLength()}
                onChange={(e) => {
                  setDupePass(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                loading={showLoad}
                disabled={!passwordsMatch() || !passwordsLength()}
                onClick={() => {
                  submitSignUp();
                }}>
                Create Account
              </LoadingButton>
            </Grid>
            <Grid item>
              <Typography>
                Already have a Curltime Account?{" "}
                <Link
                  href="#"
                  onClick={() => {
                    handleToggleToSignIn(true);
                  }}>
                  Login!
                </Link>
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <CodeVerification
              email={email}
              postConfrimHandle={postConfirmSignIn}
              isSignUp={true}
            />
          </>
        )}
      </Grid>
    </>
  );
};
