import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  Button,
  CircularProgress,
} from "@mui/material";
import { API, Auth } from "aws-amplify";
import {
  CurltimeApplication,
  CurltimeManual,
  CurltimeRelease,
} from "../../API";
import { manualByReleaseDate } from "../../graphql/queries";
import { listCurltimeApplicationsWithReleasesDesc } from "../../graphql-custom/customQueries";
import { DownloadFormModal } from "./DownloadFormModal";
import { downloadFile } from "./DownloadHelper";
import { Authentication } from "../Authentication";
import { useAppSelector } from "../../redux/hooks";
import { LoadingButton } from "@mui/lab";

export const Downloads = () => {
  const refetch = useAppSelector((state) => state.auth.refresh);
  const [isLoading, setIsLoading] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [showDownloadForm, setShowDownloadForm] = useState(false);
  const [selectedDownload, setSelectedDownload] =
    useState<CurltimeRelease | null>(null);
  const [applications, setApplications] = useState<CurltimeApplication[]>([]);
  const [manuals, setManuals] = useState<CurltimeManual[]>([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const checkLoggedInStatus = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setAuthenticated(true);
      return true;
    } catch (e) {
      setAuthenticated(false);
      return false;
    }
  };

  const getApplications = async () => {
    try {
      const resp: any = await API.graphql({
        query: listCurltimeApplicationsWithReleasesDesc,
      });
      setApplications(resp.data.listCurltimeApplications.items);
    } catch (e) {
      console.error(e);
    }
  };

  const getManuals = async () => {
    try {
      const resp: any = await API.graphql({
        query: manualByReleaseDate,
        authMode: (await checkLoggedInStatus())
          ? "AMAZON_COGNITO_USER_POOLS"
          : "AWS_IAM",
        variables: { dummyhash: "dummyhash", sortDirection: "DESC" },
      });
      setManuals(resp.data?.manualByReleaseDate.items!);
    } catch (e) {
      console.error(e);
    }
  };

  const handleShowDownloadForm = (selectedRelease: CurltimeApplication) => {
    setSelectedDownload(selectedRelease.releases?.items[0]!);
    setShowDownloadForm(true);
  };

  const downloadManual = async (manual: CurltimeManual) => {
    try {
      setIsDownloading(true);
      await downloadFile(manual);
    } catch (e) {
      console.error(e);
    } finally {
      setIsDownloading(false);
    }
  };

  const onLoad = async () => {
    setIsLoading(true);
    setApplications([]);
    setManuals([]);
    await Promise.allSettled([
      await getApplications(),
      await getManuals(),
      await checkLoggedInStatus(),
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, [refetch]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <DownloadFormModal
        open={showDownloadForm}
        selectedRelease={selectedDownload}
        handleClose={() => {
          setShowDownloadForm(false);
        }}
      />
      <Authentication
        open={showAuth}
        handleClose={() => {
          checkLoggedInStatus();
          getApplications();
          getManuals();
          setShowAuth(false);
        }}
      />
      <Grid
        container
        direction={"column"}
        alignItems={"center"}
        sx={{ maxWidth: 1000 }}
        spacing={2}>
        <Grid item>
          <Typography variant="h1">Downloads</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            CurlTime Pro and the CurlTime instruction manual are free to
            download by any member of the World Curling Federation. There are a
            number of premium features that are not automatically activated and
            will require an additional charge. Unless you are intending on
            integrating with broadcast television, this will not affect the
            majority of users.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            *** Please note CurlTime Pro will expire at the end of August each
            year, necessitating a new download for each upcoming season. ***
          </Typography>
        </Grid>
        <Grid item>
          {!authenticated && !isLoading && (
            <Button
              variant="contained"
              onClick={() => {
                setShowAuth(true);
              }}>
              Sign in for free to access CurlTime{" "}
            </Button>
          )}
        </Grid>
        {!isLoading ? (
          <Grid
            item
            container
            direction={"column"}
            spacing={2}
            justifyContent={"center"}>
            {applications?.map((application) => (
              <Grid item>
                <Accordion>
                  <AccordionSummary>
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent="space-between">
                      <Grid item>
                        <Typography>{application.name}</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            handleShowDownloadForm(application);
                          }}>
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                </Accordion>
              </Grid>
            ))}
            {manuals?.map((manual) => (
              <Grid item>
                <Accordion>
                  <AccordionSummary>
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent="space-between">
                      <Grid item>
                        <Typography>
                          {manual.name} - Created by Phil McKenzie
                        </Typography>
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          loading={isDownloading}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            downloadManual(manual);
                          }}>
                          Download
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
