import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Grid, TextField, Typography, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { successfulAlert, errorAlert } from "../../redux/features/snackbar";
import { useAppDispatch } from "../../redux/hooks";

export const CodeVerification = (props: any) => {
  const { email, postConfrimHandle, isSignUp } = props;
  const dispatch = useAppDispatch();
  const [verifyCode, setVerifyCode] = useState("");
  const [newPass, setNewPass] = useState("");
  const [disableResendVerification, setDisableResendVerification] =
    useState(false);
  const [showLoad, setShowLoad] = useState(false);

  const submitVerificationCode = async () => {
    try {
      setShowLoad(true);
      if (isSignUp) {
        await Auth.confirmSignUp(email, verifyCode);
        dispatch(successfulAlert("Successfully verified your email!"));
      } else {
        await Auth.forgotPasswordSubmit(email, verifyCode, newPass);
        dispatch(successfulAlert("Successfully changed password!"));
      }

      postConfrimHandle();
    } catch (e) {
      console.error(e);
      dispatch(
        errorAlert(
          `Something went wrong submitting verification ${JSON.stringify(e)}`
        )
      );
    } finally {
      setShowLoad(false);
    }
  };

  const resendVerificationCode = async () => {
    try {
      setDisableResendVerification(true);
      if (isSignUp) {
        await Auth.resendSignUp(email);
      } else {
        await Auth.forgotPassword(email);
      }
      dispatch(successfulAlert(`Resent Verification Code to: ${email}`));
    } catch (e) {
      console.error(e);
      dispatch(
        errorAlert(`Something went wrong resending verification code ${e}`)
      );
    } finally {
      setDisableResendVerification(false);
    }
  };

  return (
    <>
      <Grid
        container
        direction={"column"}
        spacing={2}
        alignItems={"center"}
        sx={{ padding: 2 }}>
        <Grid item>
          <TextField
            required
            value={verifyCode}
            variant="outlined"
            label="Verification code"
            onChange={(e) => {
              setVerifyCode(e.target.value);
            }}
          />
        </Grid>
        {!isSignUp && (
          <Grid item>
            <TextField
              label="New Password"
              type="password"
              onChange={(e) => {
                setNewPass(e.target.value);
              }}
            />
          </Grid>
        )}
        <Grid item>
          <LoadingButton
            variant="contained"
            loading={showLoad}
            onClick={() => {
              submitVerificationCode();
            }}>
            Verify
          </LoadingButton>
        </Grid>

        <Grid item>
          <Typography>
            Didn't get verification code?{" "}
            {disableResendVerification ? (
              "Sending..."
            ) : (
              <Link
                href="#"
                onClick={() => {
                  resendVerificationCode();
                }}>
                Send again
              </Link>
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
