import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
  Grid,
  SelectChangeEvent,
  LinearProgress,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import React, { useState } from "react";
import { CountrySelect, CountryType } from "../Generics/CountrySelect";
import { LoadingButton } from "@mui/lab";
import { CreateDownloadFormInput } from "../../API";
import { API } from "aws-amplify";
import { createDownloadForm } from "../../graphql/mutations";
import { downloadFile } from "./DownloadHelper";
import { useAppDispatch } from "../../redux/hooks";
import { successfulAlert, errorAlert } from "../../redux/features/snackbar";

export enum IntendedUse {
  PERSONAL = "Personal",
  ORG = "Organizational",
}

export const DownloadFormModal = (props: any) => {
  const { open, handleClose, selectedRelease } = props;
  const dispatch = useAppDispatch();

  const [eventName, setEventName] = useState("");
  const [venueName, setVenueName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState<CountryType | null>();
  const [intendedUse, setIntendedUse] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [showDownloadProgress, setShowDownloadProgress] = useState(false);

  const handleReset = () => {
    setEventName("");
    setVenueName("");
    setCity("");
    setCountry(null);
    setIntendedUse(null);
    setDownloadProgress(0);
  };

  const submitForm = async () => {
    try {
      setIsSubmitting(true);
      const input: CreateDownloadFormInput = {
        event: eventName,
        venue: venueName,
        city: city,
        country: country?.label!,
        intendedUse: intendedUse!,
      };

      await API.graphql({
        query: createDownloadForm,
        variables: { input: input },
      });
      setShowDownloadProgress(true);
      await downloadFile(selectedRelease, (newVal: number) =>
        setDownloadProgress(newVal)
      );
      dispatch(successfulAlert("Successfully downloaded file"));
      handleReset();
      handleClose();
    } catch (e) {
      dispatch(errorAlert("Please enter required information"));
      console.error(e);
    } finally {
      setShowDownloadProgress(false);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          Survey
          <IconButton
            onClick={() => {
              handleClose();
            }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction={"column"} spacing={2} sx={{ padding: 2 }}>
            <Grid item>
              <TextField
                label="Event Name"
                fullWidth
                onChange={(e) => {
                  setEventName(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Club/Venue"
                fullWidth
                onChange={(e) => {
                  setVenueName(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="City"
                fullWidth
                required
                helperText="City you're using CurlTime in"
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <CountrySelect
                required
                helperText="Country you're using CurlTime in"
                changeHandler={(newVal: CountryType | null) => {
                  if (newVal !== null) {
                    setCountry(newVal);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Select
                fullWidth
                required
                onChange={(e: SelectChangeEvent) => {
                  setIntendedUse(e.target.value);
                }}>
                <MenuItem value={IntendedUse.PERSONAL}>
                  {IntendedUse.PERSONAL}
                </MenuItem>
                <MenuItem value={IntendedUse.ORG}>{IntendedUse.ORG}</MenuItem>
              </Select>
              <FormHelperText>Intended Use*</FormHelperText>
            </Grid>
            <Grid item container justifyContent={"center"} spacing={2}>
              <Grid item>
                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  onClick={() => {
                    submitForm();
                  }}>
                  Download
                </LoadingButton>
              </Grid>
            </Grid>
            {isSubmitting && (
              <Grid
                item
                container
                spacing={3}
                justifyContent={"space-around"}
                alignItems={"center"}>
                <Grid item xs={10}>
                  <LinearProgress
                    variant="determinate"
                    value={downloadProgress}
                  />
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="body2"
                    color="text.secondary">{`${Math.round(
                    downloadProgress
                  )}%`}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
