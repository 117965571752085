import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Typography,
  CardContent,
  Fade,
  Button,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CurltimeLogo from "../../Images/curltime.png";
import DDRLogo from "../../Images/ddr_live.png";
import CurltimeHeroShot from "../../Images/curltime_inuse.jpg";
import WCFLogo from "../../Images/wcf_logo.png";

export const Home = () => {
  const navigate = useNavigate();

  function daysIntoYear(date:Date){
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
  }
  const now = new Date()

  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      spacing={2}
      sx={{ padding: 2 }}>
      <Grid item>
        <Card>
          <CardMedia
            component="img"
            src={CurltimeHeroShot}
            sx={{ width: "70vw" }}
          />
        </Card>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent={"center"}
        alignItems={"center"}
        spacing={3}>
        <Grid item>
          <LazyLoadImage
            src={CurltimeLogo}
            alt="Curltime"
            style={{ maxWidth: "20rem" }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h4">
            The Timing Standard for Curling Worldwide
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="error"
          size="large"
          onClick={() => {
            navigate("/downloads");
          }}>
          { daysIntoYear(now) > 243 ? `${now.getFullYear()}-${now.getFullYear() + 1}` : `${now.getFullYear() - 1}-${now.getFullYear()}` } Version Available for Download
        </Button>
      </Grid>
      <Grid item>
        <Typography>
          The World Curling Federation makes it possible for member
          organizations to download CurlTime free of charge.
        </Typography>
      </Grid>
      <Fade in={true} timeout={{ enter: 700 }}>
        <Grid item>
          <Card sx={{ maxWidth: 1200, padding: 1 }} elevation={4}>
            <CardContent>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography variant="h4">Partners</Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={"space-evenly"}
                  alignItems={"center"}
                  spacing={2}>
                  <Grid item>
                    <Card>
                      <CardActionArea sx={{ padding: 2 }}>
                        <a
                          href="https://worldcurling.org/"
                          target="_blank"
                          rel="noopener">
                          <LazyLoadImage
                            src={WCFLogo}
                            alt={"World Curling Federation"}
                            style={{ maxWidth: 300 }}
                          />
                        </a>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      Since 2006, CurlTime has been adopted by the World Curling
                      Federation as the standard timing system for all WCF
                      events worldwide. As such, CurlTime is being used in over
                      40 nations around the globe. The new CurlTime Pro has many
                      new and useful features for timekeepers, timing
                      supervisors and for broadcast television (allowing for all
                      timing data to be streamed live). Despite the new
                      interface, we have attempted to maintain as much ease of
                      use as possible.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={"space-evenly"}
                  alignItems={"center"}
                  spacing={2}>
                  <Grid item>
                    <Card>
                      <CardActionArea sx={{ padding: 2 }}>
                        <a
                          href="https://ddr.live/"
                          target="_blank"
                          rel="noopener">
                          <LazyLoadImage
                            src={DDRLogo}
                            alt={"DDR.Live Inc."}
                            style={{ maxWidth: 300 }}
                          />
                        </a>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      DDR.Live is an international entertainment technology
                      company offering innovative and reliable cloud-based
                      production solutions to make their client's creative
                      visions a virtual and physical reality. CurlTime is
                      working together with DDR.Live to bring a new era of
                      real-time sports data analysis to the world of Curling.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Fade>
    </Grid>
  );
};
