import React from "react";
import {
  Card,
  CardMedia,
  Grid,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CurltimeLogo from "../../Images/curltime.png";

export const Contact = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", padding: 2 }}>
      <Grid
        container
        spacing={2}
        direction={"row"}
        justifyContent="center"
        sx={{ maxWidth: 1000 }}>
        <Grid container item direction={"column"} xs>
          <Grid item>
            <Typography variant="h4">Contact</Typography>
          </Grid>
          <Grid item>
            <Typography>MediaSmith Productions</Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Link href="mailto:information@curltime.com">
                information@curltime.com
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <LazyLoadImage
              src={CurltimeLogo}
              alt="Curltime"
              style={{ maxWidth: 350 }}
            />
          </Grid>
        </Grid>
        <Grid item container direction={"column"} xs>
          <Grid item>
            <Typography variant="h5">Feedback</Typography>
          </Grid>
          <Grid item>
            <Typography>
              We welcome your feedback. It will help us improve and make a
              better application. While all suggestions are encouraged, not all
              will necessarily be implemented.{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">Acknowledgements</Typography>
          </Grid>
          <Grid item>
            <Typography>
              CurlTime was developed originally in a northern town in Canada.
              Since then, it has become a truly global application. I would like
              to name names, but to respect online privacy I will merely outline
              which countries have been instrumental in the development of the
              software. Our biggest, earliest and longest contributor resides in
              a small town in Scotland. He has been instrumental in finding the
              flaws and errors for us to repair. We have also received enormous
              help from the Netherlands, Canada, Switzerland, Sweden, United
              States, Spain and of course from my business partner in Germany.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
