/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurltimeApplication = /* GraphQL */ `
  query GetCurltimeApplication($id: ID!) {
    getCurltimeApplication(id: $id) {
      id
      name
      releases {
        items {
          id
          applicationId
          name
          description
          file
          filetype
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCurltimeApplications = /* GraphQL */ `
  query ListCurltimeApplications(
    $filter: ModelCurltimeApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurltimeApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        releases {
          nextToken
          __typename
        }
        groups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCurltimeRelease = /* GraphQL */ `
  query GetCurltimeRelease($id: ID!) {
    getCurltimeRelease(id: $id) {
      id
      applicationId
      name
      description
      file
      filetype
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCurltimeReleases = /* GraphQL */ `
  query ListCurltimeReleases(
    $filter: ModelCurltimeReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurltimeReleases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationId
        name
        description
        file
        filetype
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDownloadForm = /* GraphQL */ `
  query GetDownloadForm($id: ID!) {
    getDownloadForm(id: $id) {
      id
      event
      venue
      city
      country
      intendedUse
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDownloadForms = /* GraphQL */ `
  query ListDownloadForms(
    $filter: ModelDownloadFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDownloadForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event
        venue
        city
        country
        intendedUse
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const applicationReleaseByDate = /* GraphQL */ `
  query ApplicationReleaseByDate(
    $applicationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCurltimeReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicationReleaseByDate(
      applicationId: $applicationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationId
        name
        description
        file
        filetype
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCurltimeManual = /* GraphQL */ `
  query GetCurltimeManual($id: ID!) {
    getCurltimeManual(id: $id) {
      id
      name
      file
      filetype
      dummyhash
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCurltimeManuals = /* GraphQL */ `
  query ListCurltimeManuals(
    $filter: ModelCurltimeManualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurltimeManuals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        file
        filetype
        dummyhash
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const manualByReleaseDate = /* GraphQL */ `
  query ManualByReleaseDate(
    $dummyhash: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCurltimeManualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    manualByReleaseDate(
      dummyhash: $dummyhash
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        file
        filetype
        dummyhash
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
