import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getGroups, Group } from "./AdminQueries";
import { Multiselect } from "../Generics/Multiselect";
import { API } from "aws-amplify";
import { createCurltimeApplication } from "../../graphql/mutations";
import { CreateCurltimeApplicationInput } from "../../API";

const GRID_PADDING = 2;

export const CreateCurltimeApplicationModal = (props: any) => {
  const { open, handleClose, handleGetApplications } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [applicationName, setApplicationName] = useState("");
  const [groups, setGroups] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

  const handleSelectedGroupChange = (newValue: string[]) => {
    setSelectedGroups(newValue);
  };

  const handleGetGroups = async () => {
    setGroups((await getGroups()).map((group: Group) => group.GroupName));
  };

  useEffect(() => {
    handleGetGroups();
  }, []);

  const handleCreateApplication = async () => {
    try {
      const input: CreateCurltimeApplicationInput = {
        name: applicationName,
        groups: selectedGroups,
      };
      const resp = await API.graphql({
        query: createCurltimeApplication,
        variables: { input: input },
      });
      console.log(resp);
    } catch (e) {
      console.error(e);
    } finally {
      handleGetApplications();
    }
  };

  // Edit these to make your steps
  const steps = [
    {
      name: "Name Application",
      content: (
        <>
          <Grid container sx={{ padding: GRID_PADDING }}>
            <TextField
              fullWidth
              label="Name for Application"
              onChange={(e) => {
                setApplicationName(e.target.value);
              }}
            />
          </Grid>
        </>
      ),
    },
    {
      name: "Select Accessable Groups",
      content: (
        <>
          <Grid container sx={{ padding: GRID_PADDING }}>
            <Multiselect
              values={groups}
              displayKey={"GroupName"}
              changeHandler={handleSelectedGroupChange}
              label={"Groups"}
              selection={selectedGroups}
            />
          </Grid>
        </>
      ),
    },
  ];

  // Have this return true if you'd like to skip a step
  const isStepOptional = (step: number) => {
    return false;
  };

  // Keeps track of skipped steps
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSkipped((prevSkipped: Set<number>) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setApplicationName("");
    setSelectedGroups([]);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        Create Release
        <IconButton
          onClick={() => {
            handleReset();
            handleClose();
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label.name} {...stepProps}>
                  <StepLabel {...labelProps}>{label.name}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>Created Application</Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {steps[activeStep].content}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button
                    onClick={() => {
                      handleCreateApplication();
                      handleNext();
                    }}>
                    Create
                  </Button>
                ) : (
                  <Button onClick={handleNext}>Next</Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
