import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AdminTabs } from "./AdminTabs";
import { useAppSelector } from "../../redux/hooks";

export const Admin = () => {
  const navigate = useNavigate();
  const refresh = useAppSelector((state) => state.auth.refresh);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isUserAdmin = async () => {
    try {
      setIsLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      setIsAdmin(
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "Admin"
        )
      );
    } catch (e) {
      console.error(e);
      setIsAdmin(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isUserAdmin();
  }, [refresh]);

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : isAdmin ? (
        <AdminTabs />
      ) : (
        navigate("/")
      )}
    </>
  );
};
