import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  Chip,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";

export const Multiselect = (props: any) => {
  const { values, changeHandler, label, selection } = props;

  const handleChange = (event: SelectChangeEvent<any>) => {
    changeHandler(event.target.value);
  };

  return (
    <>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id={`multiselect-label-${label}`}>{label}</InputLabel>
        <Select
          labelId={`multiselect-label-${label}`}
          id={`multiselect-${label}`}
          multiple
          autoWidth
          value={selection}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value: any) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}>
          {values.map((value: any) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
