import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import {
  listCurltimeReleases,
  manualByReleaseDate,
} from "../../graphql/queries";
import {
  CurltimeApplication,
  CurltimeRelease,
  CurltimeManual,
} from "../../API";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  AlertColor,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Tooltip,
} from "@mui/material";
import { Add, Close, Delete, Edit, UploadFile } from "@mui/icons-material";
import { listCurltimeApplicationsWithReleasesDesc } from "../../graphql-custom/customQueries";
import { CreateCurltimeReleaseModal } from "./CreateCurltimeReleaseModal";
import {
  deleteCurltimeManual,
  deleteCurltimeRelease,
} from "../../graphql/mutations";
import { CreateCurltimeApplicationModal } from "./CreateCurltimeApplicationModal";
import { EditCurltimeApplicationModal } from "./EditCurltimeApplicationModal";
import { CreateCurltimeManualModal } from "./CreateManualModal";
import { successfulAlert, errorAlert } from "../../redux/features/snackbar";
import { useAppDispatch } from "../../redux/hooks";

export const CurltimeReleaseAdmin = () => {
  const dispatch = useAppDispatch();

  const [applications, setApplications] = useState<CurltimeApplication[]>([]);
  const [manuals, setManuals] = useState<CurltimeManual[]>([]);
  const [selectedApplicationId, setSelectedApplicationId] = useState("");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openCreateApplicationModal, setOpenCreateApplicationModal] =
    useState(false);
  const [openEditApplicationModal, setOpenEditApplicationModal] =
    useState(false);
  const [openCreateManualModal, setOpenCreateManualModal] = useState(false);
  const [deletingRelease, setDeletingRelease] = useState(false);

  const handleCloseModal = () => {
    setOpenCreateModal(false);
  };

  const handleCloseApplicationModal = () => {
    setOpenCreateApplicationModal(false);
  };

  const handleCloseEditApplicationModal = () => {
    setOpenEditApplicationModal(false);
  };

  const getApplications = async () => {
    try {
      const resp: any = await API.graphql({
        query: listCurltimeApplicationsWithReleasesDesc,
      });
      setApplications(resp.data.listCurltimeApplications.items);
    } catch (e) {
      console.error(e);
    }
  };

  const getManuals = async () => {
    try {
      const resp: any = await API.graphql({
        query: manualByReleaseDate,
        variables: { dummyhash: "dummyhash", sortDirection: "DESC" },
      });
      setManuals(resp.data?.manualByReleaseDate.items!);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getApplications();
    getManuals();
  }, []);

  const handleDeleteRelease = async (release: CurltimeRelease) => {
    try {
      setDeletingRelease(true);
      await API.graphql({
        query: deleteCurltimeRelease,
        variables: { input: { id: release.id } },
      });
      await Storage.remove(release.file);
      dispatch(
        successfulAlert(`Successfully deleted release: ${release.name}`)
      );
    } catch (e) {
      console.error(e);
      dispatch(
        errorAlert(
          `Something went wrong deleting release: ${release.name} - ${e}`
        )
      );
    } finally {
      setDeletingRelease(false);
      getApplications();
    }
  };

  const handleDeleteManual = async (manual: CurltimeManual) => {
    try {
      setDeletingRelease(true);
      await API.graphql({
        query: deleteCurltimeManual,
        variables: { input: { id: manual.id } },
      });
      await Storage.remove(manual.file);
      dispatch(successfulAlert(`Successfully deleted release: ${manual.name}`));
    } catch (e) {
      console.error(e);
      dispatch(
        errorAlert(
          `Something went wrong deleting release: ${manual.name} - ${e}`
        )
      );
    } finally {
      setDeletingRelease(false);
      getManuals();
    }
  };

  return (
    <>
      <CreateCurltimeApplicationModal
        open={openCreateApplicationModal}
        handleClose={handleCloseApplicationModal}
        handleGetApplications={getApplications}
      />
      <CreateCurltimeReleaseModal
        open={openCreateModal}
        applicationId={selectedApplicationId}
        handleClose={handleCloseModal}
        handleGetApplications={getApplications}
      />
      <EditCurltimeApplicationModal
        open={openEditApplicationModal}
        applicationId={selectedApplicationId}
        handleClose={handleCloseEditApplicationModal}
        handleGetApplications={getApplications}
      />
      <CreateCurltimeManualModal
        open={openCreateManualModal}
        handleClose={() => {
          setOpenCreateManualModal(false);
        }}
        handleRefreshManuals={getManuals}
      />
      <Button
        variant="contained"
        onClick={() => {
          setOpenCreateApplicationModal(true);
        }}>
        Create Application
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          setOpenCreateManualModal(true);
        }}>
        Create Manual
      </Button>

      {applications.length > 0 &&
        applications?.map((application) => (
          <>
            <Accordion>
              <AccordionSummary>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}>
                  <Grid item>
                    <Typography sx={{ flexGrow: 1 }}>
                      {application.name}{" "}
                      {application.releases
                        ? ` - ${application.releases.items.length} Releases`
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Create Release">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setSelectedApplicationId(application.id);
                          setOpenCreateModal(true);
                        }}>
                        <Add />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Application">
                      <IconButton
                        color="info"
                        onClick={() => {
                          setSelectedApplicationId(application.id);
                          setOpenEditApplicationModal(true);
                        }}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {application.releases?.items.length! > 0 ? (
                          <>
                            {Object.keys(application.releases?.items[0]!).map(
                              (releaseKey: string) => (
                                <TableCell>{releaseKey}</TableCell>
                              )
                            )}{" "}
                            <TableCell>Delete</TableCell>
                          </>
                        ) : (
                          <Typography>
                            There are no releases for this application!
                          </Typography>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {application.releases?.items.map((release: any) => (
                        <TableRow>
                          {Object.keys(release).map((key: any) => (
                            <TableCell>{release[key]}</TableCell>
                          ))}
                          <TableCell>
                            <IconButton
                              disabled={deletingRelease}
                              onClick={() => handleDeleteRelease(release)}>
                              <Close
                                color={deletingRelease ? "disabled" : "error"}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </>
        ))}
      {manuals.length > 0 &&
        manuals.map((manual) => (
          <Accordion>
            <AccordionSummary>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}>
                <Grid item>
                  <Typography sx={{ flexGrow: 1 }}>{manual.name}</Typography>
                </Grid>
                <Grid item>
                  <Tooltip title="Delete Manual">
                    <IconButton
                      color="error"
                      disabled={deletingRelease}
                      onClick={() => {
                        handleDeleteManual(manual);
                      }}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </AccordionSummary>
          </Accordion>
        ))}
    </>
  );
};
